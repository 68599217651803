var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-form',{staticClass:"login-form",attrs:{"id":"components-form-demo-normal-login","form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'userName',
          {
            rules: [{ required: true, message: '请输入用户名!' }],
          } ]),expression:"[\n          'userName',\n          {\n            rules: [{ required: true, message: '请输入用户名!' }],\n          },\n        ]"}],attrs:{"placeholder":"用户名"}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'password',
          {
            rules: [{ required: true, message: '请输入密码!' }],
          } ]),expression:"[\n          'password',\n          {\n            rules: [{ required: true, message: '请输入密码!' }],\n          },\n        ]"}],attrs:{"type":"password","placeholder":"密码"}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-space',{attrs:{"align":"center"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'captcha',
            {
              rules: [{ required: true, message: '请输入验证码!' }],
            } ]),expression:"[\n            'captcha',\n            {\n              rules: [{ required: true, message: '请输入验证码!' }],\n            },\n          ]"}],ref:"resetCaptcha",attrs:{"type":"text","placeholder":"验证码"}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1),_c('CaptCha',{ref:"reloadCaptcha",attrs:{"getKey":_vm.getKey}})],1)],1),_c('a-button',{staticClass:"login-form-button",staticStyle:{"width":"100%"},attrs:{"type":"primary","html-type":"submit"}},[_vm._v(" 登录 ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }