<template>
  <div style="height: 100%">
    <a-layout
      id="components-layout-demo-custom-trigger"
      class="layout"
      style="height: 100%"
    >
      <a-layout-sider v-model="collapsed" :trigger="null" collapsible>
        <div class="logo" />
        <!-- /dashboard/user/add 变成 /dashboard/user -->
        <a-menu theme="dark" mode="inline" :defaultSelectedKeys="[$route.path]" :defaultOpenKeys="[parseUrl()]">
          <a-menu-item
            :key="index"
            v-for="(item, index) in acl"
            v-show="item.children.length === 0"
            @click="go(item.auth_path)"
          >
            {{ item.auth_name }}
          </a-menu-item>
          <a-sub-menu
            :key="item.auth_path"
            v-for="item in acl"
            v-show="item.children.length"
            :title="item.auth_name"
          >
            <a-menu-item
              :key="el.auth_path"
              v-for="el in item.children"
              v-show="el.is_nav === 1"
              @click="go(el.auth_path)"
            >
              {{ el.auth_name }}
            </a-menu-item>
          </a-sub-menu>
        </a-menu>
      </a-layout-sider>
      <a-layout>
        <a-layout-header style="background: #fff; padding: 0">
          <a-icon
            class="trigger"
            :type="collapsed ? 'menu-unfold' : 'menu-fold'"
            @click="() => (collapsed = !collapsed)"
          />
          <span class="message" v-if="adminInfo !== null"
            >你好,{{ adminInfo.username }}({{
              adminInfo.role
            }})!您的账号上次于{{ adminInfo.last_login_addr.state }}/{{
              adminInfo.last_login_addr.isp
            }}[{{ adminInfo.last_ip }}]登录！</span
          >
          <a-button class="exit" type="primary" @click="showModal"
            >退出</a-button
          >
          <a-modal
            v-model="visible"
            @ok="handleOk"
            okText="确定"
            cancelText="取消"
          >
            <h5 style="fontsize: 20px">确定退出吗?</h5>
          </a-modal>
        </a-layout-header>
        <a-layout-content
          :style="{
            margin: '24px 16px',
            padding: '24px',
            background: '#fff',
            minHeight: '280px',
          }"
        >
          <!-- 路由想要在哪显示 就要放在哪里！！！ -->
          <router-view></router-view>
        </a-layout-content>
      </a-layout>
    </a-layout>
  </div>
</template>

<script>
import url from "../../config/url";
export default {
  data() {
    return {
      collapsed: false,
      adminInfo: null,
      acl: [],
      visible: false,
    };
  },
  methods: {
    go(url) {
      this.$router.push(url);
      console.log(this.$route)
    },
    //将后端返回的权限acl数据解析出来
    parseAcl() {
      this.acl = JSON.parse(localStorage.getItem("acl"));
    },
    showModal() {
      this.visible = true;
    },
    handleOk() {
      this.$router.push("/login");
      localStorage.removeItem("jwt");
      localStorage.removeItem("acl");
    },
    parseUrl(){
      let index = this.$route.path.lastIndexOf('/')
      return this.$route.path.slice(0,index)
    }
  },
  beforeCreate() {
    this.$http.get(url.PreCheck).then((res) => {
      if (res.data.errNo !== 0) {
        localStorage.removeItem("jwt");
        localStorage.removeItem("acl");
        this.$message.error("未登录，请登录!");
        this.$router.push("/login");
      } else {
        this.$http.get(url.GetAdminInfo).then((res) => {
          this.adminInfo = res.data.accountInfo;
        });
      }
    });
  },
  mounted() {
    this.parseAcl();
  },
  updated() {
    this.defaultSelectedKeys = this.$route.path;
  },
  handleOk(e) {
    console.log(e);
    this.visible = false;
  },
};
</script>

<style lang="scss" scoped>
#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}
.ant-layout-content {
  overflow-y: scroll;
  //滚动条隐藏 兼容
  scrollbar-width: none;
}
::-webkit-scrollbar {
  display: none;
}
#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  background: url("../../assets/images/logo.webp")-10px -26px;
  background-size: 90%;
  margin: 16px;
}
.message {
  letter-spacing: 1px;
}
.exit {
  margin-left: 30px;
}
.ant-layout-header{
  white-space: nowrap;
}
</style>
