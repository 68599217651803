<template>
  <div :id="id" :style="{ width: w, height: h }"></div>
</template>

<script>
import * as echarts from "echarts";  
import moment from "moment";
export default {
  props: {
    id: {
      default: "请输入id",
    },
    text: {
      default: "请输入标题",
    },
    name: {
      default: "请输入名称",
    }, 
    dataList: {
      default: [],
    },
    w: {
      default: "600px",
    },
    h: {
      default: "600px",
    },
    legend: {
      default: true,
    },
  },
  mounted() {
    this.loadEcharts();
    console.log(this.dataList);
  },
  methods: {
    loadEcharts() {
      var chartDom = document.getElementById(this.id);
      if (this.dataList) { 
        var myChart = echarts.init(chartDom);
        var option;
        option = {
          title: {
            text: this.text,
            subtext: "数据截至" + moment().format("YYYY-MM-DD"),
            left: "center",
          },
          tooltip: {
            trigger: "item",
            //计算百分比 固定写法
            formatter: "{a}<br/>{b}：{c} ({d}%)",
          },
          series: [
            {
              name: this.name,
              type: "pie",
              radius: "50%",
              data: this.dataList,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.5)",
                },
              },
            },
          ],
        };
        if (this.legend === true) {
          option.legend = {
            orient: "horizontal",
            left: "10%",
            top: "15%",
          };
        }
        option && myChart.setOption(option);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>