<template>
  <div>
    <a-form
      id="components-form-demo-normal-login"
      :form="form"
      class="login-form"
      @submit="handleSubmit"
    >
      <a-form-item>
        <a-input
          v-decorator="[
            'userName',
            {
              rules: [{ required: true, message: '请输入用户名!' }],
            },
          ]"
          placeholder="用户名"
        >
          <a-icon
            slot="prefix"
            type="user"
            style="color: rgba(0, 0, 0, 0.25)"
          />
        </a-input>
      </a-form-item>
      <a-form-item>
        <a-input
          v-decorator="[
            'password',
            {
              rules: [{ required: true, message: '请输入密码!' }],
            },
          ]"
          type="password"
          placeholder="密码"
        >
          <a-icon
            slot="prefix"
            type="lock"
            style="color: rgba(0, 0, 0, 0.25)"
          />
        </a-input>
      </a-form-item>
      <a-form-item>
        <a-space align="center">
          <a-input
            ref="resetCaptcha"
            v-decorator="[
              'captcha',
              {
                rules: [{ required: true, message: '请输入验证码!' }],
              },
            ]"
            type="text"
            placeholder="验证码"
          >
            <a-icon
              slot="prefix"
              type="lock"
              style="color: rgba(0, 0, 0, 0.25)"
            />
          </a-input>
          <CaptCha ref="reloadCaptcha" :getKey="getKey"></CaptCha>
        </a-space>
      </a-form-item>
      <a-button
        type="primary"
        html-type="submit"
        class="login-form-button"
        style="width: 100%"
      >
        登录
      </a-button>
    </a-form>
  </div>
</template>

<script>
import CaptCha from "../../components/CaptCha.vue";
import url from "../../config/url";
export default {
  components: {
    CaptCha,
  },
  data() {
    return {
      //用来接受CaptCha子组件 通过回调函数 传递的key
      key: "",
    };
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "normal_login" });
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        //组件自带方式，帮助我们收集数据
        //将验证码的key 存放到values中 然后一起发送到服务端
        values["key"] = this.key;
        if (!err) {
          //普通登录发送请求
          this.$http.post(url.NormalLogin, values).then((res) => {
            // console.log(res);
            if (res.data.errNo === 0) {
              //登录成功 跳转到管理平台首页面
              this.$router.push("/dashboard");
            } else {
              //清空验证码的内容
              this.form.setFieldsValue({
                captcha: "",
              });
              //刷新验证码
              this.$refs.reloadCaptcha.getCaptCha();
            }
          });
        }
      });
    },
    //该方法要传递给captcha组件，用来获取返回的key
    getKey(key) {
      this.key = key;
    },
  },
};
</script>

<style lang="scss" scoped></style>
