var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-form',{staticClass:"login-form",attrs:{"id":"components-form-demo-normal-login","form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'mobile',
          {
            rules: [{ required: true, message: '请输入手机号!' }],
          } ]),expression:"[\n          'mobile',\n          {\n            rules: [{ required: true, message: '请输入手机号!' }],\n          },\n        ]"}],attrs:{"placeholder":"手机号"}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-space',{attrs:{"align":"center"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'captcha',
            {
              rules: [{ required: true, message: '请输入图片验证码!' }],
            } ]),expression:"[\n            'captcha',\n            {\n              rules: [{ required: true, message: '请输入图片验证码!' }],\n            },\n          ]"}],ref:"resetCaptcha",attrs:{"type":"text","placeholder":"验证码"}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1),_c('CaptCha',{ref:"reloadCaptcha",attrs:{"getKey":_vm.getKey}})],1)],1),_c('a-form-item',[_c('a-space',{attrs:{"align":"center"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'smscode',
            {
              rules: [{ required: true, message: '请输入手机验证码!' }],
            } ]),expression:"[\n            'smscode',\n            {\n              rules: [{ required: true, message: '请输入手机验证码!' }],\n            },\n          ]"}],ref:"resetCaptcha",attrs:{"type":"text","placeholder":"手机验证码"}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"hourglass"},slot:"prefix"})],1),_c('a-button',{staticClass:"login-form-button",staticStyle:{"width":"100%"},attrs:{"type":"primary","html-type":"submit","disabled":this.smsFlag},on:{"click":_vm.getSmsCode}},[_vm._v(" "+_vm._s(this.time >= this.setTime ? "获取手机验证码" : this.time + "s后重发")+" ")])],1)],1),_c('a-button',{staticClass:"login-form-button",staticStyle:{"width":"100%"},attrs:{"type":"primary","html-type":"submit"}},[_vm._v(" 立即登录 ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }