<template>
  <div>
    <a-page-header
      id="header"
      style="border: 1px solid rgb(235, 237, 240)"
      title="用户管理"
      sub-title="用户列表"
      @back="() => null"
    >
      <div>
        <a-button type="primary" @click="showModal"> 添加用户 </a-button>
        <div v-show="isShow" id="modal">
          <a-form
            labelAlign="left"
            id="addUser"
            :form="form"
            @submit="handleSubmit"
          >
            <a-form-item v-bind="formItemLayout" label="用户名">
              <a-input
                v-decorator="[
                  'username',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入用户名!',
                        whitespace: true,
                      },
                      {
                        min: 5,
                        message: '至少5个字符',
                      },
                    ],
                  },
                ]"
                placeholder="输入用户名"
              />
            </a-form-item>

            <a-form-item v-bind="formItemLayout" label="密码">
              <a-input
                v-decorator="[
                  'password',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入密码!',
                      },
                      {
                        pattern: /^[\w\W]{6,}$/,
                        message: '密码至少6位',
                      },
                    ],
                  },
                ]"
                type="password"
                placeholder="输入密码"
              />
            </a-form-item>

            <a-form-item v-bind="formItemLayout" label="性别">
              <a-radio-group v-model="value" @change="onChange">
                <a-radio :value="1"> 男 </a-radio>
                <a-radio :value="2"> 女 </a-radio>
                <a-radio :value="3"> 保密 </a-radio>
              </a-radio-group>
            </a-form-item>

            <a-form-item v-bind="formItemLayout" label="邮箱">
              <a-input
                v-decorator="[
                  'email',
                  {
                    rules: [
                      {
                        type: 'email',
                        message: '不符合邮箱格式！',
                      },
                      {
                        required: true,
                        message: '请输入邮箱',
                      },
                    ],
                  },
                ]"
                placeholder="输入邮箱"
              />
            </a-form-item>

            <a-form-item v-bind="formItemLayout" label="手机号码">
              <a-input
                v-decorator="[
                  'mobile',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入手机号!',
                      },
                      {
                        pattern: /0?(13|14|15|17|18|19)[0-9]{9}/,
                        message: '请输入正常的手机号!',
                      },
                      {
                        max: 11,
                        message: '好长的手机号……',
                      },
                    ],
                  },
                ]"
                style="width: 100%"
              >
                <a-select
                  slot="addonBefore"
                  v-decorator="['prefix', { initialValue: '86' }]"
                  style="width: 70px"
                >
                  <a-select-option value="86"> +86 </a-select-option>
                  <a-select-option value="87"> +87 </a-select-option>
                </a-select>
              </a-input>
            </a-form-item>

            <a-form-item>
              <a-button
                @keyup="register"
                style="margin: 5px"
                type="primary"
                html-type="submit"
              >
                注册
              </a-button>
              <a-button style="margin: 5px" type="primary" @click="reset">
                重置
              </a-button>
              <a-button
                style="margin: 5px"
                type="primary"
                @keyup="exitReigster"
                @click="isShow = false"
              >
                退出
              </a-button>
            </a-form-item>
          </a-form>
        </div>
      </div>
    </a-page-header>
    <!-- rowKey 表格每行唯一key值  row形参代表每一行的数据 对象格式 -->
    <a-table
      :pagination="false"
      :columns="columns"
      :data-source="data"
      :rowKey="(row) => row.id"
    >
      <span slot="button" slot-scope="text,record" id="operate"  ref="mydel">
        <a-button type="primary" size="small">编辑</a-button>
        <a-popconfirm
          @confirm="del(record.id)"
          title="确定删除吗？"
          ok-text="确定"
          cancel-text="取消"
        >
          <a href="#" >删除</a>
        </a-popconfirm>
      </span>
      
    </a-table>
    <!-- 关于分页的实现方式: 
        前端[客户端]分页  接口返回全部数据  由前端页面通过JS进行数据分割,来实现分页 点击分页是不会向服务器请求数据的
        100条   每页展示部分数据10条   所有数据进行切割  splice(从哪儿开始,截取几个)等
        // 每页条数
        let pageNum = 10 
        let start = 0
        第一页   splice(start,pageNum)  0,1,2,3,4,5,6,7,8,9
        第二页   spilice(start+pageNum-1,pageNum) 

        后端[服务端]分页  数据接口 分批返回部分数据  设置了接收参数page第几页。点击分页会向服务器请求数据
            优势:每次加载一部分,速度更快 按需加载 -->
    <!-- 小细节，change事件后的函数不可以加() 否则直接调用 -->
    <div id="pagination">
      <a-pagination
        :total="total"
        @change="loadData"
        v-model="current"
        :showQuickJumper="true"
      />
    </div>
  </div>
</template>

<script>
import url from "@/config/url";
const columns = [
  {
    title: "序号",
    // 对应获取的数据源的字段名
    dataIndex: "id",
    // 唯一key
    key: "id",
    align: "center",
  },
  {
    title: "用户名",
    dataIndex: "username",
    key: "username",
    align: "center",
  },
  {
    title: "手机号",
    dataIndex: "mobile",
    key: "mobile",
    align: "center",
  },
  {
    title: "邮箱",
    dataIndex: "email",
    key: "email",
    align: "center",
  },
  {
    title: "性别",
    dataIndex: "gender",
    key: "gender",
    align: "center",
  },
  {
    title: "状态",
    dataIndex: "status",
    key: "status",
    align: "center",
  },
  {
    title: "操作",
    dataIndex: "operate",
    key: "operate",
    align: "center",  
    scopedSlots: { customRender: "button"},
  },
];
export default {
  data() {
    return {
      columns,
      data: [],
      total: 0,
      current: 1,
      visible: false,
      confirmDirty: false,
      value: 3,
      isShow: false,
      gender: 3,
      formItemLayout: {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 8 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 },
        },
      },
    };
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "register" });
  },
  created() {
    this.loadData();
  },
  mounted() {
    let that = this;
    document.addEventListener("keydown", that.exitReigster);
    document.addEventListener("keydown", that.register);
  },
  methods: {
    loadData(page = 1) {
      this.$http.get(url.GetUserList, { params: { page } }).then((res) => {
        console.log(res);
        const { data, total, current_page } = res.data.paginate;
        this.data = data;
        this.total = total;
        this.current_page = current_page;
        let arr = res.data.paginate.data;
        let arr_gender = arr.map((el) => {
          switch (el.gender) {
            case "1":
              return "女";
            case "2":
              return "男";
            case "3":
              return "保密";
          }
        });
        let arr_status = arr.map((el) => {
          return el.status === "2" ? "禁用" : "正常";
        });
        let arr_mobile = arr.map((el) => {
          return el.mobile.replace(/(\d{3})\d{4}(\d{4})/, "$1****$2");
        });
        let arr_email = arr.map((el) => {
          return el.email.replace(/^(\w{1})\w{2}/, "$1***");
        });
        for (let i = 0; i < 10; i++) {
          this.data[i].gender = arr_gender[i];
          this.data[i].status = arr_status[i];
          this.data[i].mobile = arr_mobile[i];
          this.data[i].email = arr_email[i];
        }
      });
    },
    showModal() {
      this.isShow = true;
      this.reset();
    },
    reset() {
      this.form.resetFields();
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          values.gender = this.gender;
          console.log(values);
          this.$http.post(url.AddUser, values).then((res) => {
            console.log(res);
          });
          this.isShow = false;
        }
      });
    },
    onChange(event) {
      this.gender = event.target.value;
    },
    register(e) {
      if (this.isShow) {
        if (e.keyCode === 13) {
          this.handleSubmit(e);
        }
      }
    },
    exitReigster(e) {
      if (this.isShow) {
        if (e.keyCode === 27) {
          this.isShow = false;
        }
      }
    },
    del(id) {
      console.log(id);
      console.log(this.$refs.mydel);
    },
  },
};
</script>

<style lang="scss" scoped>
#header {
  display: flex;
}
#operate {
  display: flex;
  justify-content: space-around;
}
#pagination {
  text-align: center;
  margin-top: 20px;
}
#modal {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background: rgba($color: #000000, $alpha: 0.4);
}
#addUser {
  width: 400px;
  height: 450px;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 100;
  background-color: white;
  text-align: center;
  padding: 20px;
  border-radius: 20px;
  /* background-color: orange; */
  box-sizing: border-box;
  padding-top: 40px;
}
</style>
