import Vue from 'vue'
import VueRouter from 'vue-router'

import Login from '../views/login/Login.vue'

import DashBoardRouters from './modules/DashBoard'

// 将VueRouter原型上的push方法,重写,再放回去 解决：编程式导航在跳转到与当前地址一致的URL时会报错，但这个报错不影响功能  
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    component: Login
  },
  ...DashBoardRouters

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
