<template>
  <div>
    <img :src="src" @click="getCaptCha" />
  </div>
</template>

<script>
import axios from "axios";
import url from '../config/url'
//取消网络请求
const source = axios.CancelToken.source();
export default {
  props: {
      //获取父组件传递过来的方法getKey
    getKey: {
        type:Function
    },
  },
  data() {
    return {
      src: "",
    };
  },
  methods: {
    getCaptCha() {
      this.$http
        .get(url.GetCaptCha, { cancelToken: source.token })
        .then((res) => {
          this.src = res.data.img;
          //使用中可能需要多个验证码,在服务器端进行校验时,需要通过一个唯一标识key,来确认校验哪个验证码,key唯一值存储的位置 必须存储在上级,如果在验证码组件内部存储,生成之后就被覆盖了（因为页面中有多个验证码，验证码会生成多次），所以需要子传父（将key传递给父组件）。这里必须采用父组件传给子组件一个方法，因为axios请求是异步的，必须在then（真正的异步）里面，调用父组件的方法，通过传参的方式，将数据传递给父组件
          this.getKey(res.data.key);
        })
        .catch((e) => console.log(e.message));
    },
  },
  created() {
    this.getCaptCha();
  },
  //组件销毁前，取消请求
  beforeDestroy() {
    source.cancel("get网络繁忙，请稍后再试");
  },
};
</script>

<style lang="scss" scoped>
</style>