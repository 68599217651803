<template>
  <div id="login">
    <a-card id="box-card">
      <a-tabs default-active-key="1" :animated="false" size="large" :tabBarGutter="140">
        <a-tab-pane key="1" tab="密码登录">
          <NormalLogin></NormalLogin>
        </a-tab-pane>
        <a-tab-pane key="2" tab="短信登录">
          <MobileLogin></MobileLogin>
        </a-tab-pane>
      </a-tabs>
    </a-card>
  </div>
</template>

<script>
import NormalLogin from "./NormalLogin.vue";
import MobileLogin from "./MobileLogin.vue";
export default {
  components: {
    NormalLogin,
    MobileLogin,
  },
};
</script>

<style lang="scss" scoped>
#login {
  height: 100%;
  background-image: url("../../assets/images/bg.gif");
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}
#box-card {
  width: 400px;
  height: 380px;
}
</style>