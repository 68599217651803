// 前缀域名
const prefix = 'https://reactapi.iynn.cn'
// 接口地址的映射
const url = {
  //获取图形验证码 算数类型
  GetCaptCha: prefix + '/captcha/api/math',
  // 密码登录
  NormalLogin: prefix + '/api/common/auth/login',
  // 管理员信息
  GetAdminInfo: prefix+'/api/common/auth/adminInfo',
  // jwt校验是否登录
  PreCheck: prefix + '/api/common/auth/jwtPreCheck',
  //用户统计数据
  GetUserData: prefix + '/api/users/statistics/getData',
  //用户列表
  GetUserList: prefix + '/api/users',
  //用户添加
  AddUser: prefix + '/api/users/add',
  //用户删除
  delUser: prefix + "/api/users/?id"
}

export default url