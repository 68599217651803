<template>
  <div id="welcome" v-if="accessFrom !== null && area !== null && gender !== null">
    <Pie id="pie1" text="数据来源" :dataList="accessFrom" name="来源"></Pie>
    <Pie id="pie2" text="地域分布" :dataList="area" name="地域"></Pie>
    <Pie
      id="pie3"
      text="男女比例"
      :dataList="gender"
      :legend="false"
      name="性别"
    ></Pie>
  </div>
</template>

<script>
import Pie from "../../components/echarts/Pie.vue";
import url from "../../config/url";
export default {
  components: {
    Pie,
  },
  data() {
    return {
      accessFrom: null,
      area: null,
      gender: null,
    };
  },
  beforeCreate() {
    this.$http.get(url.GetUserData).then((res) => {
      const { accessFrom, area, gender } = res.data.data;
      this.accessFrom = accessFrom;
      this.area = area;
      this.gender = gender;
    });
  },
  updated() {
    //测试发现 只有在更新周期 才能够打印出数据，而在mounted是打印不出来的，因为ajax数据还未请求回来 所以要控制视图的渲染，当数据未发生更新的时候，不去渲染，如果发生更新说明数据取回，即可渲染
    console.log("===========================");
    console.log(this.accessFrom);
    console.log(this.area);
    console.log(this.gender);
    console.log("===========================");
  },
};
</script>

<style lang="scss" scoped>
#welcome {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
</style>
