<template>
  <div style="height: 100%">
    <a-ConfigProvider :locale="locale">
      <router-view />
    </a-ConfigProvider>
  </div>
</template>
<script>
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
export default {
  data() {
    return {
      locale: zhCN,
    };
  },
};
</script>

<style lang="scss">
html,
body {
  height: 100%;
}
* {
  margin: 0;
  padding: 0;
}
</style>
