import axios from "axios"
axios.interceptors.request.use(cfg => {
    //设置请求header头信息，添加token
    const jwt = localStorage.getItem('jwt')
    if(jwt){
        cfg.headers.Authorization = jwt
    }
    return cfg
})
axios.interceptors.response.use(res => {
     // 登录成功后存储jwt acl权限菜单列表，注意返回来的acl的数据格式为对象，存储的时候必须转成字符串进行存储!
     if(res.data.context && res.data.context.jwt){
         localStorage.setItem('jwt',res.data.context.jwt)
     }
     if(res.data.context && res.data.context.acl){
         localStorage.setItem('acl',JSON.stringify(res.data.context.acl))
     }
    return res
})

export default axios