import DashBoard from '../../views/dashboard/index.vue'
import Welcome from '../../views/dashboard/Welcome.vue'
import UserList from '../../views/dashboard/users/List.vue'
import filmList from '../../views/dashboard/film/film.vue'
import cinemaList from '../../views/dashboard/cinema/cinema.vue'
const routes = [
    {
        path: '/dashboard',
        component: DashBoard,
        redirect:'/dashboard/welcome',
        children: [
            {
                path: '/dashboard/welcome',
                component: Welcome
            },
            {
                path: '/dashboard/user/index',
                component: UserList
            },
            {
                path: '/dashboard/film/index',
                component: filmList
            },
            {
                path: '/dashboard/cinema/index',
                component: cinemaList
            }
        ]
    }
]

export default routes